<template>
  <div class="error-page">
    <div class="error-content">
      <slot name="content" />
      <div class="error-text">
        <p>Возникла ошибка, Ваш запрос не может быть закончен.</p>
        <p>
          Проверьте URL, или вернитесь
          <router-link to="/">На главную</router-link>
          , или обратитесь в
          <router-link to="/">Службу поддержки</router-link>
        </p>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<style lang="scss">
.error-page {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  font-family: "Roboto", sans-serif;
  height: calc(100vh - 8px);
  color: #3f5171;
  box-sizing: border-box;

  h1 {
    font-weight: normal;
    font-size: 38px;
    width: 100%;
    text-align: center;
  }
  p {
    font-size: 14px;
  }
  svg {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 568px;
  }
  a {
    color: #1f8efa;
  }
}
.error-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.error-text {
  margin-top: 12px;
  width: 100%;
  text-align: center;
}
</style>
